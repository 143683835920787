import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import { Container, Row, Col, Button, Nav, DropdownButton, Dropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Avatar, Box, Card, Grid, Popover, Tooltip } from '@mui/material';
import Share from '../common/Share';
// import videotest from '../../../assets/video/sample-video.mp4'
import VideoJS from '../../../player/player'
import videojs from 'video.js';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import playIcon from '../../../assets/regal_images/play-button.png'
import { ROUTES } from '../../../constant/routes';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import trending1 from '../../../assets/images/trending/01.jpg'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as SeriesAction from '../../../actions/SeriesActions'
import * as MovieAction from '../../../actions/MovieAction'

import { useHistory } from 'react-router-dom';
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import freeEpisodeImg from '../../../assets/regal_images/Free.png'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

// require("@silvermine/videojs-quality-selector")(videojs);
// require("@silvermine/videojs-quality-selector")(videojs);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




export default function SeriesDetails() {
    const playerRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const param = useParams()
    console.log(param)
    const id = open ? 'simple-popover' : undefined;
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const location = useLocation()
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const dispatch = useDispatch()
    const { getSeriesDetails } = bindActionCreators(SeriesAction, dispatch)
    const { addToLikedSeries } = SeriesAction
    const [seriesData, setSeriesData] = useState("")
    const [seriesIndex, setSeriesIndex] = useState(0)
    const [save, setSave] = useState(false)
    const history = useHistory()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const [slug, setSlug] = useState("")

    const { removeFromWatchlist } = MovieAction


    useEffect(() => {
        (async () => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            console.log(location?.state)
            const res = await axios.get("https://api.ipify.org/?format=json");

            if (param?.slug) {
                console.log(location.state)
                let userObj = {
                    // id: location?.state?.series,
                    profile_id: profile_id ? profile_id : null,
                    user_id: user_id ? user_id : null,
                    ip_address: res?.data?.ip
                }
                const resData = await getSeriesDetails(param?.slug, userObj)
                console.log("data", resData)
                setSeriesData(resData)
            }
        })()
    }, [save, param?.slug])


    const handleChangeIndex = (index) => {
        setSeriesIndex(index)
        setSave(!save)
    };

    const videoJsOptions = {
        autoplay: true,
        loop: true,
        controls: false,
        responsive: true,
        fluid: true,
        muted: true,
        playbackRates: [0.5, 1, 1.5, 2],
        userActions: { hotkeys: true },
        controlBar: {
            qualitySelector: true,
            pictureInPictureToggle: false,

        },

        languages: [{}],
        sources: [
            {
                src: `${seriesData?.data?.free_preview}`,
                // src: "https://tygon.s3.ap-south-1.amazonaws.com/m3u8/Bhoori/1080p.m3u8",
                type: `${seriesData?.data?.free_preview.split('.').pop().toLowerCase() == 'm3u8' ? "application/x-mpegURL" : "video/mp4"}`,
                label: "1080p",
                selected: true,
            }
        ]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.controlBar.addChild('button', {
            controlText: 'Title',
            className: 'vjs-visible-text',
            position: 2,
        });
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTrailer = () => {
        let userObj = {
            movie_name: seriesData?.data?.series_name,
            Trailer: seriesData?.data?.series_trailer
        }

        history.push(ROUTES.trailer, { data: userObj })
    }

    const playEpisode = (data, next = null) => {
        if (isLoggedIn) {
            if (isLoggedIn && data?.is_episode_free == "Yes") {
                history.push(ROUTES.episode, { data: data, nextEpisode: next })
            }
            else if (isLoggedIn && seriesData?.data?.series_access == "TVOD" && !seriesData?.is_rental_expiry) {
                history.push(ROUTES.episode, { data: data, nextEpisode: next })
            }
            else if (isLoggedIn && seriesData?.data?.series_access == "SVOD" && !seriesData?.is_subscription_expiry) {
                history.push(ROUTES.episode, { data: data, nextEpisode: next })
            }
            else if (isLoggedIn && seriesData?.data?.series_access == "TVOD" && seriesData?.is_rental_expiry) {
                if (seriesData?.data?.first_episode_free == "Yes" && data?.episode_number == "Episode 1") {
                    history.push(ROUTES.episode, { data: data, nextEpisode: [] })

                }
                else {
                    history.push("/price-plan", { currency: seriesData?.data?.currency_symbol, transactionType: "TVOD", available_for: seriesData?.data?.available_for_days, seriesName: seriesData?.data?.series_name, series: seriesData?.data?.id, categoryType: "Series", slug: seriesData?.data?.slug, price: seriesData?.data?.rental_price })
                }
            }
            else if (isLoggedIn && seriesData?.data?.series_access == "SVOD" && seriesData?.is_subscription_expiry) {
                toast.info("Please subscribe now to continue.")
            }
            else if (isLoggedIn && seriesData?.data?.series_access == "Free") {
                history.push(ROUTES.episode, { data: data, nextEpisode: next })
            }
        }
        else {
            toast.info("Please login to continue.")
        }

    }

    const addSeriesIntoWatchList = async (data = null) => {
        console.log(seriesData)
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                series: data ? data : seriesData?.data?.id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }

    const RemoveWatchList = async (data = null) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: "",
                series_id: data ? data : seriesData?.data?.id
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }

    const likedSeries = async () => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                series_id: seriesData?.data?.id,
                series_like: seriesData?.is_series_like == true ? 0 : 1,
                series_dislike: seriesData?.is_series_like ? 1 : 0,
                profile_id: profile_id
            }
            const resData = await addToLikedSeries(userObj)
            if (resData?.statuscode == 200) {
                setSave(!save)
            }
        }
        else {
            toast.info("Please login to continue.")
        }


    }

    const handleRedirect = (data) => {
        history.push(ROUTES.seriesDetails, { series: data?.id })
    }

    const handleClick = (event, slug) => {
        setAnchorEl(event.currentTarget);
        setSlug(slug)
    };
    return (
        <>
            {/* <AlertMessage /> */}


            <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                <div
                    onClick={(e) => {
                        playerRef.current.muted(!playerRef.current.muted())
                        document.getElementById("mute-button").className = !playerRef.current.muted() ? "ri-volume-up-fill" : "ri-volume-mute-fill"
                        // document.getElementById("mute-button1").className = !playerRef.current.muted() ? "display-none" : "fa fa-times"

                    }}
                    style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "flex-end",
                        right: "4vw",
                        top: "20vh",
                        zIndex: "10",
                        fontSize: "20px"
                    }}>
                    <div style={{ background: "#00000047", padding: "18px", borderRadius: "50%", height: "60px", border: "1px solid #ffffff", display: "flex", alignItems: "center" }}>
                        <i className="ri-volume-mute-fill" id='mute-button' aria-hidden="true"></i>

                    </div>
                </div>
                <div
                    id="home-slider"
                    className="slick-bg-1 m-0 p-0">
                    <div className='bg-video'>
                        {seriesData?.data?.free_preview && <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />}
                    </div>

                    <SwiperSlide className="slick-bg-2">
                        <Container fluid className="position-relative h-100">
                            <div className="slider-inner h-100">
                                <Row className="align-items-center iq-ltr-direction h-100 ">
                                    <Col xl="6" lg="12" md="12">
                                        <Link to="#">
                                            {/* <div className="channel-logo" data-iq-delay="0.5"> */}
                                                {/* <img src={IMAGE_ENDPOINT + seriesData?.data?.series_logo} className="c-logo" alt={seriesData?.data?.series_name} /> */}
                                                <h3 className="trending-text big-title text-uppercase mt-0 mb-2">{seriesData?.data?.series_name}</h3>
                                            {/* </div> */}
                                        </Link>

                                        <div className="flex-wrap align-items-center">
                                            <div className="flex-wrap align-items-center">
                                                <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" dangerouslySetInnerHTML={{ __html: seriesData?.data?.series_description?.length <= 250 ? seriesData?.data?.series_description : seriesData?.data?.series_description?.substring(0, 250) + "..." }}>
                                                </p>
                                            </div>

                                            <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                <p>
                                                    <b>Language: </b><span>{seriesData?.data?.language?.language_name}</span>
                                                    <br />
                                                    <b>Genres:</b> {seriesData?.data?.genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}
                                                </p>

                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center r-mb-23 dropdown-season" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">

                                            {seriesData?.data?.series_access == "SVOD" &&
                                                (seriesData?.is_subscription_expiry ?
                                                    <Button className="btn btn-hover iq-button" onClick={() => history.push("/price-plan")}>
                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Subscribe Now
                                                    </Button> :

                                                    <div className='dropdown-btn drpdwn-colour'>
                                                        <DropdownButton id="dropdown-item-button" title={seriesData?.data?.data[seriesIndex]?.name}>
                                                            {
                                                                seriesData?.data?.data?.map((data, index) => (
                                                                    <Dropdown.Item as="button" style={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeIndex(index)} key={index}>{data?.name}</Dropdown.Item>
                                                                ))
                                                            }
                                                        </DropdownButton>
                                                    </div>)
                                            }
                                            {seriesData?.data?.series_access == "Free" &&
                                                <div className='dropdown-btn drpdwn-colour'>
                                                    <DropdownButton id="dropdown-item-button" title={seriesData?.data?.data[seriesIndex]?.name}>
                                                        {
                                                            seriesData?.data?.data?.map((data, index) => (
                                                                <Dropdown.Item as="button" style={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeIndex(index)} key={index}>{data?.name}</Dropdown.Item>
                                                            ))
                                                        }
                                                    </DropdownButton>
                                                </div>
                                            }

                                            {seriesData?.data?.series_access == "TVOD" &&
                                                (seriesData?.is_rental_expiry ?
                                                    <>
                                                        <button className={"btn btn-hover iq-button"} onClick={() => history.push("/price-plan", { currency: seriesData?.data?.currency_symbol, transactionType: "TVOD", available_for: seriesData?.data?.available_for_days, seriesName: seriesData?.data?.series_name, series: seriesData?.data?.id, categoryType: "Series", slug: seriesData?.data?.slug, price: seriesData?.data?.rental_price })}>Pay & Watch : {seriesData?.data?.currency_symbol}{Number(seriesData?.data?.rental_price).toFixed(2)}</button>
                                                        {/* <RazorpayButton className={"btn btn-hover iq-button"} title={"Rent Now : $"+Number(movieData?.Data?.rental_price).toFixed(2)} forceRefresh={setSave} transactionType={"TVOD"} movie={movieData?.Data?.id} categoryType={"Movie"} price={movieData?.Data?.rental_price}/> */}
                                                    </>
                                                    :
                                                    <div >
                                                        <div className='dropdown-btn drpdwn-colour'>

                                                            <DropdownButton id="dropdown-item-button" title={seriesData?.data?.data[seriesIndex]?.name}>
                                                                {
                                                                    seriesData?.data?.data?.map((data, index) => (
                                                                        <Dropdown.Item as="button" style={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeIndex(index)} key={index}>{data?.name}</Dropdown.Item>
                                                                    ))
                                                                }
                                                            </DropdownButton>
                                                        </div>
                                                        <span style={{ fontSize: "12px", display: "flex", justifyContent: "center", marginTop: ".3rem", textTransform: "none" }}>{seriesData?.days_count} {seriesData?.days_count > 1 ? "days" : "day"} remaining</span>
                                                    </div>

                                                )}

                                            {
                                                seriesData?.data?.first_episode_free == 'Yes' &&
                                                <div
                                                    className='icon-border ml-2'
                                                    style={{ whiteSpace: "nowrap", padding: "10px 24px", cursor: "pointer" }}
                                                    onClick={() => playEpisode(seriesData?.data?.data[seriesIndex]?.data?.[0])}
                                                >
                                                    <i className="fa fa-play"></i> Free Episode
                                                </div>

                                            }
                                            {
                                                seriesData?.isWatchlist ?
                                                    <div onClick={() => RemoveWatchList()}>
                                                        <i className="fa fa-check icon-border ml-2"></i>
                                                    </div>
                                                    :
                                                    <div onClick={() => addSeriesIntoWatchList()}>
                                                        <i className="fa fa-plus icon-border ml-2"></i>
                                                    </div>
                                            }


                                            <div onClick={(event) => handleClick(event, seriesData?.data?.slug)} >
                                                <i className="fa fa-share icon-border ml-2"></i>
                                            </div>
                                            <div onClick={likedSeries} style={{ cursor: "pointer" }}>
                                                <i className="fa fa-heart icon-border ml-2" style={{ color: `${seriesData?.is_series_like ? " #ba000b" : ""}` }}></i>
                                            </div>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Typography sx={{ p: 2 }}>
                                                    <Share sharedURL={`${window.location.href}`} />
                                                </Typography>
                                            </Popover>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Container>
                    </SwiperSlide>
                </div>

            </section>

            {/* cast */}




            <div>
                <Swiper onSwiper={setThumbsSwiper}
                    slidesPerView={1}
                    freeMode={true}
                    watchSlidesProgress={true}
                    id="trending-slider"
                    className="mt-3  list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction">
                    <SwiperSlide as="li">
                        <div className="tranding-block position-relative"
                            style={{ backgroundImage: `url(${trending1})` }}>
                            <Tab.Container defaultActiveKey="trending-data4" className="trending-custom-tab">
                                <div className="tab-title-info position-relative iq-ltr-direction">
                                    <Nav as="ul" variant="pills" className="trending-pills nav-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction">

                                        <Nav.Item as="li" className="nav-item">
                                            <Nav.Link href="" eventKey="trending-data4">Episodes</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item as="li" className="nav-item">
                                            <Nav.Link href="" eventKey="trending-data1">Trailer</Nav.Link>
                                        </Nav.Item>

                                        {/* <Nav.Item as="li" className="nav-item">
                                            <Nav.Link href="" eventKey="trending-data2">Cast</Nav.Link>
                                        </Nav.Item> */}

                                        <Nav.Item as="li" className="nav-item">
                                            <Nav.Link href="" eventKey="trending-data3">Description</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <Tab.Content className="trending-content">
                                    <Tab.Pane eventKey="trending-data4" className="overlay-tab show fade">
                                        <section id="parallex" className="">
                                            <Container fluid className="h-100">
                                                {
                                                    seriesData?.data?.data[seriesIndex]?.data?.map((data, index) => (
                                                        <Row className="align-items-center justify-content-center h-100 parallaxt-details episodes-box series-detail-hover-class" key={index}>
                                                            <Col lg="4">
                                                                <div className="">
                                                                    <img src={IMAGE_ENDPOINT + data?.episode_poster} className="img-fluid w-100 episodes" alt="bailey" />
                                                                </div>
                                                                <div className="block-icon-series">
                                                                    <div role="button" className="">
                                                                        <img src={playIcon} alt='play' onClick={() => playEpisode(data, seriesData?.data?.data[seriesIndex]?.data)} />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    index == 0 &&
                                                                    <div className="block-icon-freeseries">
                                                                        <div role="button" className="">
                                                                            <img src={freeEpisodeImg} style={{ display: `${seriesData?.data?.first_episode_free == 'Yes' ? "" : "none"}` }} alt='play' onClick={() => playEpisode(data)} className='free-episode' />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col lg="8" className="r-mb-23">
                                                                <div className="text-left">
                                                                    <div className="movie-time d-flex align-items-center iq-ltr-direction">
                                                                        {/* <div className="badge badge-secondary mr-3">Season 1</div> */}
                                                                        <h6 style={{ color: "#ba000b" }} className="text-whit mt-2">{data?.episode_number} - {data?.episode_title}</h6>
                                                                    </div>
                                                                    <p className="pt-2" style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: data?.episode_description }}></p>
                                                                    {/* <div className="parallax-buttons">
                                                                    <Link to="/show-details" className="btn btn-hover iq-button">
                                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                                    </Link>
                                                                </div> */}
                                                                    <div className="">
                                                                        <p className="text-white"><b>Duration :</b> {data?.episode_duration}</p>
                                                                    </div>
                                                                    {/* <div className="">
                                                                        <p className="text-white"><b>Released On :</b> {new Date(data?.release_date).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}</p>
                                                                    </div> */}
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    ))
                                                }

                                            </Container>
                                        </section>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="trending-data1" className="overlay-tab show fade">
                                        <section id="parallex" className="">
                                            <Container fluid className="h-100">
                                                <Row className="align-items-center justify-content-center h-100 parallaxt-details">
                                                    <Col lg="4">
                                                        {/* <div className="parallax-img"> */}
                                                        <div className="">

                                                            <img src={IMAGE_ENDPOINT + seriesData?.data?.series_poster} className="img-fluid w-100" alt="bailey" />
                                                            <div className="block-play">
                                                                <div role="button" className="">
                                                                    {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                            x="0px" y="0px" width="80px" height="80px" viewBox="0 0 213.7 213.7"
                                                                            enableBackground="new 0 0 213.7 213.7" xmlSpace="preserve">
                                                                            <polygon className='triangle' fill=" #ba000b" strokeWidth="7" strokeLinecap="round"
                                                                                strokeLinejoin="round" strokeMiterlimit="10"
                                                                                points="73.5,62.5 148.5,105.8 73.5,149.1 " />
                                                                            <circle className='circle' fill=" #ba000b" strokeWidth="7" strokeLinecap="round"
                                                                                strokeLinejoin="round" strokeMiterlimit="10" cx="106.8" cy="106.8" r="103.3" />
                                                                        </svg> */}
                                                                    <img src={playIcon} alt='play' onClick={handleTrailer} />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg="6" className="r-mb-23">
                                                        <div className="text-left">


                                                            <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction">
                                                                {/* <div className="badge badge-secondary mr-3">13+</div> */}
                                                                <h6 className="text-white">{seriesData?.data?.series_name}</h6>
                                                            </div>
                                                            <p dangerouslySetInnerHTML={{ __html: seriesData?.data?.series_description }}></p>
                                                            {/* <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction"> */}
                                                            <p className="text-white"><b>Language :</b>{seriesData?.data?.language?.language_name}</p>
                                                            {/* </div> */}
                                                            <p className="text-white"><b>Genre :</b>{seriesData?.data?.genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}</p>

                                                            {/* <div className="parallax-buttons">
                                                                <Link to="/show-details" className="btn btn-hover iq-button">
                                                                    <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                                </Link>
                                                            </div> */}
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </section>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="trending-data2" className="overlay-tab  fade show " id="trending-episode1">
                                        <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                            <Row className='mt-3'>
                                                <Grid container spacing={2} className='mt-3'>
                                                    <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
                                                        <Card
                                                            sx={{
                                                                fontSize: 13,
                                                                background: "none",
                                                                boxShadow: 'none',
                                                                color: "black",
                                                            }}
                                                        >
                                                            <Card
                                                                sx={{
                                                                    fontSize: 13,
                                                                    mt: "10px",
                                                                    background: "none",
                                                                    boxShadow: 'none',
                                                                    color: "black",
                                                                    textAlign: "center",
                                                                    width: "fit-content",
                                                                    ml: "15px",
                                                                }}
                                                            >
                                                                <img
                                                                    src={`https://upload.wikimedia.org/wikipedia/commons/2/27/Ranbir_Kapoor_at_the_trailer_launch_of_Shamshera.jpg`}
                                                                    // width="70%"
                                                                    style={{
                                                                        borderRadius: "30px",
                                                                        alignSelf: 'center',
                                                                        alignContent: 'center',
                                                                        minHeight: '170px',
                                                                        maxHeight: '170px',
                                                                        // width: "100%",
                                                                        minWidth: "100%",
                                                                        maxWidth: "100%",
                                                                        backgroundSize: "cover"
                                                                    }} />
                                                                <h6 className='mt-2' style={{ color: "#b9213a" }} >Actor</h6>
                                                                <h6 style={{ color: "white" }} className='mt-1'>Ranveer Kapoor</h6>

                                                            </Card>
                                                        </Card>
                                                    </Grid>

                                                </Grid>
                                            </Row>

                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="trending-data3" className="overlay-tab fade show">
                                        <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">

                                            <div>
                                                <h5>Series Description</h5>
                                                <p className='mt-2' dangerouslySetInnerHTML={{ __html: seriesData?.data?.series_description }}></p>
                                            </div>
                                            {/* <h5>Total seasons and episodes</h5> */}
                                            {
                                                seriesData?.data?.data.map((data, index) => (
                                                    <div key={index}>
                                                        <p className='mt-2'><b>{data?.name}</b></p>
                                                        <ul>

                                                            {
                                                                data?.data?.map((name, index1) => (
                                                                    <li key={index1}>{name?.episode_number} - {name?.episode_title}</li>

                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>



            {/* Related */}
            <div className='main-content'>
                <section id="iq-favorites">
                    <Container fluid>
                        <Row>
                            <Col sm="12" className="overflow-hidden">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="main-title">Related Series</h4>
                                    <Link className="iq-view-all" to="/movie-category">View All</Link>
                                </div>
                                <div id="favorites-contens">
                                    {/* <div id="prev1" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                    <div id="next1" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                    <Swiper
                                        navigation={{
                                            prevEl: '#prev4',
                                            nextEl: '#next4'
                                        }}
                                        breakpoints={{
                                            320: { slidesPerView: 1 },
                                            400: { slidesPerView: 1 },
                                            550: { slidesPerView: 2 },
                                            991: { slidesPerView: 3 },
                                            1400: { slidesPerView: 4 }
                                        }}
                                        loop={false}
                                        slidesPerView={4}
                                        spaceBetween={20}
                                        as="ul"
                                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction mt-3">
                                        {
                                            seriesData?.Related_Series?.map((data, index) => (
                                                <SwiperSlide as="li" key={index}>
                                                    <div className="position-relative series-div">
                                                        <div>
                                                            <img src={`${IMAGE_ENDPOINT + data?.series_poster}`} className="series-img-slider" alt={data?.series_name} />
                                                        </div>
                                                        <div className='hover-class-series' style={{ width: "100%" }}>
                                                            {/* <div className='pt-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <h5>{data?.series_name}</h5>
                                                                <div style={{ fontSize: "25px" }}>
                                                                    <i className="fa fa-plus-circle mr-3" aria-hidden="true"></i>
                                                                    <i className="fa fa-share-alt-square" aria-hidden="true"></i>
                                                                </div>
                                                            </div> */}
                                                            <div>
                                                                <Grid container spacing={2} className='pb-2'>
                                                                    <Grid item xs={4}>
                                                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                                            <img src={`${IMAGE_ENDPOINT + data?.series_thumbnail}`} className="hover-thumbnail" style={{ cursor: "pointer" }} />
                                                                        </Link>

                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>

                                                                            <p style={{ fontWeight: "bolder", cursor: "pointer" }}>{data?.series_name}</p>
                                                                            <p style={{ marginTop: "-1rem", cursor: "pointer" }} dangerouslySetInnerHTML={{ __html: data?.series_description?.length <= 80 ? data?.series_description : data?.series_description?.substring(0, 80) + "..." }}></p>
                                                                        </Link>
                                                                        <div style={{ display: "flex", justifyContent: "end", fontSize: "30px", alignItems: "center" }}>
                                                                            {/* <div onClick={() => addSeriesIntoWatchList(data?.id)}><i className="fa fa-plus-square mr-2" aria-hidden="true"></i></div> */}
                                                                            {
                                                                                data?.isWatchlist ?
                                                                                    <div onClick={() => RemoveWatchList(data?.id)}>
                                                                                        <i className="fa fa-check-square"></i>
                                                                                    </div>
                                                                                    :
                                                                                    <div onClick={() => addSeriesIntoWatchList(data?.id)}>
                                                                                        <i className="fa fa-plus-square"></i>
                                                                                    </div>
                                                                            }
                                                                            {/* <i className="fa fa-share-alt-square ml-2" aria-hidden="true"></i> */}

                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>

                                                                <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem" }} className=''>
                                                                    <div style={{ fontSize: "16px", margin: "0 auto", padding: "10px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="block-description pb-1">
                                                            {/* <h6 className="iq-title"><Link to="/show-details">{data?.series_name}</Link></h6> */}
                                                            <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">

                                                            </div>

                                                        </div>

                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }

                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    )
}
