import axios from "axios";
import {
  GET_MOVIE_DETAILS,
  GET_MOVIE_DETAILS_ERROR,
  GET_SLIDER_DATA,
  GET_SLIDER_DATA_ERROR,
  REGISTER_MESSAGE,
  OTP_MESSAGE,
  LOGIN_MESSAGE,
  PROFILE_MESSAGE,
  COMPLAINT_MESSAGE,
  AVATARS,
  PROFILES,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  REFUND_POLICY,
  ABOUT_US,
  GET_ALL_HOME_DATA,
  GET_ALL_HOME_DATA_ERROR,
  EDIT_USER_DETAILS_MESSAGE,
  CHANGE_PASSWORD_MESSAGE,
  LOGOUT_MESSAGE,
  LIVE_STREAMING_DASHBOARD_DATA,
  LIVE_STREAMING_DASHBOARD_DATA_ERROR,
  GET_STREAM_DETAILS,
  GET_STREAM_DETAILS_ERROR,
  USER_DETAILS,
  FORGOT_PASSWORD_MESSAGE,
  NOTIFICATIONS,
  USER_LOGIN_LIST,
  USER_LOGOUT_MESSAGE,
  RUNNING_STATUS,
  FORGOT_PROFILE_PIN_MESSAGE,
  LOGIN_WITH_OTP,
  LOGIN_WITH_OTP_ERROR,
  SAVE_USER_DETAILS,
  SAVE_USER_DETAILS_ERROR,
  WEB_LOGIN_USING_EMAIL,
  WEB_LOGIN_USING_EMAIL_ERROR
} from "../Actiontype/ActionType";
import { GET_MOVIE_DETAILS_API, GET_SLIDER_DATA_API, REGISTER_API, LOGIN_API, OTP_API, all_avatar_list_api, profile_create_api, user_profile_list_api, complaint_create_api, privacy_policy_api, terms_and_conditions_api, refund_policy_api, GET_ALL_HOME_DATA_API, edit_user_details_api, change_password_api, about_us_api, LOGOUT_API, LIVE_STREAMING_DASHBOARD_DATA_API, GET_STREAM_DETAILS_API, user_details_web_api, update_password_api, forgot_password_send_otp_api, forgot_password_verify_otp_api, resend_otp_api, user_notification_api, notification_read_status_update_api, user_login_list_api, user_logout_api, user_login_status_api, profile_edit_api, profile_delete_api, change_running_status_api, profile_pin_verification_api, forgot_profile_pin_api, LOGIN_WITH_OTP_API, PRE_LOGIN_API, SAVE_USER_DETAILS_API, customer_delete, LOGIN_WITH_OTP_SEND_API, WEB_LOGIN_USING_EMAIL_API } from "../constant/ApiList";
import { Base64 } from "js-base64";


export const getSliderData = (userObj) => {
  return async (dispatch) => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return await axios
      .post(GET_SLIDER_DATA_API, { ...userObj, ip_address: res.data.ip })
      .then((res) => {
        console.log("dddddd", res)
        dispatch({
          type: GET_SLIDER_DATA,
          payload: JSON.parse(Base64.decode(res?.data?.data))
        });
        return JSON.parse(Base64.decode(res?.data?.data));
      }).catch((error) => {
        dispatch({
          type: GET_SLIDER_DATA_ERROR
        });
        return error.response;
      })
  }
}

export const getMovieDetails = (slug, userObj) => {
  return async (dispatch) => {
    return await axios
      .post(`${GET_MOVIE_DETAILS_API}/${slug}/`, userObj)
      .then((res) => {
        dispatch({
          type: GET_MOVIE_DETAILS,
          payload: JSON.parse(Base64.decode(res?.data))
        });
        return JSON.parse(Base64.decode(res?.data));
      }).catch((error) => {
        dispatch({
          type: GET_MOVIE_DETAILS_ERROR
        });
        return error.response;
      })
  }
}

export const getStreamDetails = (slug, userObj) => {
  return async (dispatch) => {
    return await axios
      .post(`${GET_STREAM_DETAILS_API}/${slug}/`, userObj)
      .then((res) => {
        dispatch({
          type: GET_STREAM_DETAILS,
          payload: res.data
        });
        return res.data;
      }).catch((error) => {
        dispatch({
          type: GET_STREAM_DETAILS_ERROR
        });
        return error.response;
      })
  }
}

export const loginwithOTP = async (userObj) => {
  // return async (dispatch) => {
  return await axios
    .post(`${LOGIN_WITH_OTP_SEND_API}`, userObj)
    .then((res) => {
      // if (res?.data?.new_user !== "true") {
      //   sessionStorage.setItem("user", JSON.stringify(res?.data?.user_data))
      //   localStorage.setItem("user", JSON.stringify(res?.data?.user_data))
      //   localStorage.setItem("isLoggedIn", true)

      //   sessionStorage.setItem("isLoggedIn", true)
      // }

      // dispatch({
      //   type: LOGIN_WITH_OTP,
      //   payload: res.data
      // });
      return res.data;
    }).catch((error) => {
      // dispatch({
      //   type: LOGIN_WITH_OTP_ERROR
      // });
      return error.response;
    })
  // }
}

export const VerifyOTP = (userObj) => {
  return async (dispatch) => {
    return await axios
      .post(`${LOGIN_WITH_OTP_API}`, userObj)
      .then((res) => {
        if (res?.data?.new_user !== "true" && res?.data?.statuscode == 200) {
          sessionStorage.setItem("user", JSON.stringify(res?.data?.user_data))
          localStorage.setItem("user", JSON.stringify(res?.data?.user_data))
          localStorage.setItem("isLoggedIn", true)
          sessionStorage.setItem("isLoggedIn", true)
        }

        dispatch({
          type: LOGIN_WITH_OTP,
          payload: res.data
        });
        return res.data;
      }).catch((error) => {
        dispatch({
          type: LOGIN_WITH_OTP_ERROR
        });
        return error.response;
      })
  }
}

export const submitInfo = (userObj) => {
  return async (dispatch) => {
    return await axios
      .post(`${SAVE_USER_DETAILS_API}`, userObj)
      .then((res) => {
        console.log(res)
        if (res?.data?.statuscode == 200) {
          sessionStorage.setItem("user", JSON.stringify(res?.data?.user_data))
          localStorage.setItem("user", JSON.stringify(res?.data?.user_data))
          localStorage.setItem("isLoggedIn", true)


        }
        // sessionStorage.setItem("isLoggedIn", true)
        dispatch({
          type: SAVE_USER_DETAILS,
          payload: res.data
        });
        return res.data;
      }).catch((error) => {
        dispatch({
          type: SAVE_USER_DETAILS_ERROR
        });
        return error.response;
      })
  }
}

export const preLogin = async (userObj) => {
  return await axios
    .post(PRE_LOGIN_API, userObj)
    .then((res) => {
      return res.data
    }).catch((error) => {
      return error.response
    })
}


export const register = (formData) => async (dispatch) => {
  try {
    const { data } = await REGISTER_API(formData);
    dispatch({ type: REGISTER_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const resend_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await resend_otp_api(formData);
    dispatch({ type: OTP_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const user_details_web = (formData) => async (dispatch) => {
  try {
    const { data } = await user_details_web_api(formData);
    dispatch({ type: USER_DETAILS, payload: data });

    return data
  } catch (error) {
  }
};
export const edit_user_details = (formData) => async (dispatch) => {
  try {
    const { data } = await edit_user_details_api(formData);
    sessionStorage.setItem("user", JSON.stringify(data?.data))
    dispatch({ type: EDIT_USER_DETAILS_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const change_password = (formData) => async (dispatch) => {
  try {
    console.log("comes in forgot password", formData)
    const { data } = await change_password_api(formData);
    dispatch({ type: CHANGE_PASSWORD_MESSAGE, payload: data });
    return data

  } catch (error) {
    return error

  }
}; export const update_password = (formData) => async (dispatch) => {
  try {
    const { data } = await update_password_api(formData);
    dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: data });


  } catch (error) {
  }
}; export const forgot_password_send_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await forgot_password_send_otp_api(formData);
    dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: data });
    return data

  } catch (error) {
  }
}; export const forgot_password_verify_otp = (formData) => async (dispatch) => {
  try {
    const { data } = await forgot_password_verify_otp_api(formData);
    if (data?.statuscode != 200)
      dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: data });
    else
      dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: { ...data, message: null } });

    return data
  } catch (error) {
  }
};



export const otp = (formData) => async (dispatch) => {
  try {
    const { data } = await OTP_API(formData);
    dispatch({ type: OTP_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const user_login_list = (formData) => async (dispatch) => {
  try {
    const { data } = await user_login_list_api(formData);
    dispatch({ type: USER_LOGIN_LIST, payload: data });
    return data

  } catch (error) {
  }
};

export const user_logout = (formData) => async (dispatch) => {
  try {
    const { data } = await user_logout_api(formData);
    dispatch({ type: USER_LOGOUT_MESSAGE, payload: data });
    const { data1 } = await user_login_list_api(formData);
    dispatch({ type: USER_LOGIN_LIST, payload: data1 });

    return data

  } catch (error) {
  }
};

export const login = (formData, history) => async (dispatch) => {

  try {
    const { data } = await LOGIN_API(formData);
    if (data?.statuscode == 200) {
      sessionStorage.setItem("user", JSON.stringify(data?.id))
      sessionStorage.setItem("isLoggedIn", true)

      history.push("/profile_selection")
      if (formData?.remember) {
        localStorage.setItem("login_details", JSON.stringify({ ...formData }))
      }
    }
    else {
      localStorage.setItem("login_details", "{}")

    }

    dispatch({ type: LOGOUT_MESSAGE, payload: null });
    dispatch({ type: LOGIN_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const logout = (formData) => async (dispatch) => {
  try {
    const { data } = await LOGOUT_API(formData);
    sessionStorage.setItem("profile", "{}")
    sessionStorage.setItem("user", "{}")
    sessionStorage.removeItem("previospage")

    localStorage.clear()

    sessionStorage.setItem("isLoggedIn", false)
    localStorage.clear()
    dispatch({ type: LOGOUT_MESSAGE, payload: data });
    dispatch({ type: LOGIN_MESSAGE, payload: null });


  } catch (error) {
  }
};
export const user_login_status = (formData) => async (dispatch) => {
  try {
    const { data } = await user_login_status_api(formData);
    if (data?.statuscode == 406) {
      sessionStorage.setItem("profile", "{}")
      sessionStorage.setItem("user", "{}")
      sessionStorage.setItem("isLoggedIn", false)
      localStorage.setItem("login_details", "{}")
      localStorage.clear()

    }


  } catch (error) {
  }
};
export const all_avatar_list = (formData) => async (dispatch) => {
  try {
    const { data } = await all_avatar_list_api(formData);
    dispatch({ type: AVATARS, payload: data });


  } catch (error) {
  }
};
export const user_profile_list = (formData) => async (dispatch) => {
  try {
    const { data } = await user_profile_list_api(formData);
    dispatch({ type: PROFILES, payload: data });


  } catch (error) {
  }
};
export const profile_create = (formData) => async (dispatch) => {
  try {
    const { data } = await profile_create_api(formData);
    dispatch({ type: PROFILE_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const profile_pin_verification = (formData) => async (dispatch) => {
  try {
    const { data } = await profile_pin_verification_api(formData);
    dispatch({ type: PROFILE_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const forgot_profile_pin = (formData) => async (dispatch) => {
  try {
    const { data } = await forgot_profile_pin_api(formData);
    dispatch({ type: FORGOT_PROFILE_PIN_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const profile_edit = (formData) => async (dispatch) => {
  try {
    const { data } = await profile_edit_api(formData);
    dispatch({ type: PROFILE_MESSAGE, payload: data });


  } catch (error) {
  }
};

export const delete_user = (formData) => async () => {
  try {
    const { data } = await customer_delete(formData);
    // dispatch({ type: PROFILE_MESSAGE, payload: data });
    return data

  } catch (error) {
  }
};


export const profile_delete = (formData) => async (dispatch) => {
  try {
    const { data } = await profile_delete_api(formData);
    dispatch({ type: PROFILE_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const change_running_status = (formData) => async (dispatch) => {
  try {
    const { data } = await change_running_status_api(formData);
    dispatch({ type: RUNNING_STATUS, payload: data });


  } catch (error) {
  }
};
export const complaint_create = (formData) => async (dispatch) => {
  try {
    const { data } = await complaint_create_api(formData);
    dispatch({ type: COMPLAINT_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const privacy_policy = (formData) => async (dispatch) => {
  try {
    const { data } = await privacy_policy_api(formData);
    dispatch({ type: PRIVACY_POLICY, payload: data });


  } catch (error) {
  }
};
export const terms_and_conditions = (formData) => async (dispatch) => {
  try {
    const { data } = await terms_and_conditions_api(formData);
    dispatch({ type: TERMS_CONDITIONS, payload: data });


  } catch (error) {
  }
};
export const refund_policy = (formData) => async (dispatch) => {
  try {
    const { data } = await refund_policy_api(formData);
    dispatch({ type: REFUND_POLICY, payload: data });


  } catch (error) {
  }
};
export const about_us = (formData) => async (dispatch) => {
  try {
    const { data } = await about_us_api(formData);
    dispatch({ type: ABOUT_US, payload: data });


  } catch (error) {
  }
};

export const user_notification = (formData) => async (dispatch) => {
  try {
    const { data } = await user_notification_api(formData);
    dispatch({ type: NOTIFICATIONS, payload: data });


  } catch (error) {
  }
};
export const notification_read_status_update = (formData) => async (dispatch) => {
  try {
    const { read_status } = await notification_read_status_update_api(formData);
    const { data } = await user_notification_api({ user_id: formData.user });
    dispatch({ type: NOTIFICATIONS, payload: data });


  } catch (error) {
  }
};


export const getAllHomeData = (userObj) => {
  // return async (dispatch) => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   return await axios
  //     .post(GET_ALL_HOME_DATA_API, { ...userObj, ip_address: res.data.ip })
  //     .then((res) => {
  //       dispatch({
  //         type: GET_ALL_HOME_DATA,
  //         payload: res.data
  //       });
  //       return res.data;
  //     }).catch((error) => {
  //       dispatch({
  //         type: GET_ALL_HOME_DATA_ERROR
  //       });
  //       return error.response;
  //     })
  // }
}

export const live_streaming_dashboard = (userObj) => {
  return async (dispatch) => {
    return await axios
      .post(LIVE_STREAMING_DASHBOARD_DATA_API, userObj)
      .then((res) => {
        dispatch({
          type: LIVE_STREAMING_DASHBOARD_DATA,
          payload: res.data
        });
        return res.data;
      }).catch((error) => {
        dispatch({
          type: LIVE_STREAMING_DASHBOARD_DATA_ERROR
        });
        return error.response;
      })
  }
}

export const getUserStatus = async (userObj) => {
  return await axios
    .post(PRE_LOGIN_API, userObj)
    .then((res) => {
      return res.data
    }).catch((error) => {
      return error.response
    })
}

export const getCityFromIp = async (userObj) => {
  return await axios
    .post(`https://ipapi.co/${userObj?.ip_address}/json/`)
    .then((res) => {
      return res.data
    }).catch((error) => {
      return error.response
    })
}

export const loginwithGmail = (userObj) => {
  return async (dispatch) => {
    // console.log({ ...userObj, ip_address: res.data.ip })
    return await axios
      .post(WEB_LOGIN_USING_EMAIL_API, userObj)
      .then((res) => {
        if (res?.data?.new_user !== "true") {
          sessionStorage.setItem("user", JSON.stringify(res?.data?.user_data))
          localStorage.setItem("user", JSON.stringify(res?.data?.user_data))
          localStorage.setItem("isLoggedIn", true)

          sessionStorage.setItem("isLoggedIn", true)
        }
        dispatch({
          type: WEB_LOGIN_USING_EMAIL,
          payload: res.data
        });
        return res.data;
      }).catch((error) => {
        dispatch({
          type: WEB_LOGIN_USING_EMAIL_ERROR
        });
        return error.response;
      })
  }
}