import { GET_SUBSCRIPTION_DETAILS, GET_SUBSCRIPTION_DETAILS_ERROR, PAYMENT_MESSAGE, TRANSACTIONS, PROMOCODE, PAYMENT_LIST } from "../../Actiontype/ActionType"

const initstate = { subscriptionDetails: null }

const PaymentStore = (state = initstate, action) => {

    const { type, payload } = action

    switch (type) {

        case GET_SUBSCRIPTION_DETAILS: {
            return {
                ...state,
                subscriptionDetails: payload
            }
        }
        case GET_SUBSCRIPTION_DETAILS_ERROR: {
            return {
                ...state,
                subscriptionDetails: null
            }
        }
        case PAYMENT_MESSAGE:
            {
                return {
                    ...state,
                    payment_message: payload
                }
            }
        case TRANSACTIONS:
            {
                return {
                    ...state,
                    transactions: payload
                }
            }
        case PROMOCODE:
            {
                return {
                    ...state,
                    promocode: payload
                }
            }
        case PAYMENT_LIST:
            {
                return {
                    ...state,
                    listpayment: payload
                }
            }
        default:
            return state
    }

}


export default PaymentStore